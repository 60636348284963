<template>
    <div class="loginBoxComp">
        <div class="close">
            <i @click="closeBox" class="el-icon-circle-close"></i>
        </div>
        <!-- <div class="wel">Welcome</div> -->
        <div class="way">账号登录</div>
        <div class="account">
            <p>账号</p>
            <div class="in">
                <!-- <el-input v-model="telephone" placeholder="Username or email address"></el-input> -->
                <el-input :class="{'wrong':accountWrong}" v-model="telephone" placeholder="输入手机号" @input="accountInput" autocomplete="off"></el-input>
            </div>
            <div class="tip">{{accountTip}}</div>
        </div>
        <div class="pwd">
            <p>密码</p>
            <div class="in">
                <!-- <el-input v-model="password" placeholder="Password"></el-input> -->
                <el-input type="password" :class="{'wrong':pwdWrong}" v-model="password" placeholder="输入密码" @input="pwdInput" autocomplete="off"></el-input>
            </div>
            <div class="tip">{{pwdTip}}</div>
        </div>
        <div class="other">
            <el-button type="text" @click="$router.push('/forgetPwd')">忘记密码</el-button>
        </div>
        <div class="btn">
            <el-button type="primary" @click="login" :loading="loading">{{loading?"登录中":"登录"}}</el-button>
        </div>
    </div>
</template>

<script>
import {loginByPwd} from "@/axios/api"
export default {
    data(){
        return{
            telephone:"",
            password:"",
            accountTip:"",
            pwdTip:"",
            accountWrong:false,
            pwdWrong:false,
            loading:false,
        }
    },
    methods: {
        // 登录
        login(){
            if(!this.telephone){
                this.accountTip="请输入账号";
                this.accountWrong=true;
                return
            }else if(!this.$commonFun.regTelephone(this.telephone)){
                this.accountTip="请正确输入手机号";
                this.accountWrong=true;
                return
            }else{
                this.accountTip=""
            }
            if(!this.password){
                this.pwdTip="请输入密码";
                this.pwdWrong=true;
                return
            }else{
                this.pwdTip=""
            }

            this.loading=true;
            loginByPwd({
                telephone:this.telephone,
                password:this.password
            }).then(res=>{
                // console.log("登录结果",res);
                if(res.code==200){
                    localStorage.AOGEYA_expireTime=new Date().getTime();
                    localStorage.AOGEYA_loginInfo=res.data;
                    // document.cookie="Authorization="+res.data;
                    // this.$message.success(res.msg||res.message);
                    setTimeout(()=>{
                        // this.$router.push("/")
                        
                        // this.$commonFun.wrongTip(this,res.msg,'success',true)
                        this.$bus.$emit("loginStatus",1);
                        this.closeBox();
                    },1500)
                }else{
                    this.loading=false;
                    this.$commonFun.wrongTip(this,res.msg)
                }
            })
        },
        // 关闭弹框
        closeBox(){
            this.$emit("close")
        },
        // 账号输入时
        accountInput(){
            if(this.account==""){
                this.accountWrong=true
            }else{
                this.accountWrong=false
            }
        },
        // 密码输入时
        pwdInput(){
            if(this.password==""){
                this.pwdWrong=true
            }else{
                this.pwdWrong=false
            }
        }
    },
}
</script>

<style lang="less" scoped>
.loginBoxComp{
    position: absolute;
    top: 50%;
    right: 50%;
    transform: translate(50%,-50%);
    z-index: 1000;
    width: 539px;
    // height: 741px;
    height: 550px;
    border-radius: 40px;
    background: rgba(255, 255, 255, 1);
    box-shadow: 0px 2px 4px  rgba(0, 0, 0, 0.25);
    box-sizing: border-box;
    padding: 39px;
    .close{
        position: absolute;
        top: 10px;
        right: 30px;
        font-size: 30px;
        color: #333;
        cursor: pointer;
    }
    .wel{
        font-size: 20px;
        font-size: 16px;
        font-weight: 400;
        color: #333;
    }
    .way{
        margin-top: 10px;
        font-size: 50px;
        font-size: 34px;
        font-weight: 500;
        color: #333;
    }
    .account,.pwd{
        margin-top:140px;
        margin-top: 60px;
        p{
            font-size: 20px;
            font-size: 16px;
            font-weight: 400;
            color: #333;
            margin: 0;
        }
        &>div{
            margin-top: 7px;
            .el-input{
                height: 45px;
                border-radius: 10px;
                /deep/.el-input__inner{
                    background: rgba(255, 255, 255, 1);
                    border: 1px solid rgba(42, 130, 228, 1);
                    height: 45px;
                    line-height: 45px;
                }
                &.wrong{
                    /deep/.el-input__inner{
                        border-color: rgba(212, 48, 48, 1);
                    }
                }
            }
        }
        .tip{
            height: 14px;
            font-size: 12px;
            font-weight: 400;
            color: rgba(212, 48, 48, 1);
        }
    }
    .pwd{
        margin-top: 56px;
        margin-top: 40px;
    }
    .other{
        display: flex;
        justify-content: flex-end;
    }
    .btn{
        margin-top: 34px;
        margin-top: 20px;
        text-align: center;
        .el-button{
            width: 237px;
            height: 54px;
            border-radius: 10px;
            background: rgba(119, 147, 65, 1);
        }
    }
}
</style>